.spinner {
  width: 20px;
  height: 20px;
  right: 10px;
  border: 5px solid rgba(0, 0, 0, .1);
  border-left: 5px solid $primary-contrast;
  border-right: 5px solid $primary-contrast;
  animation: spinner 1s linear infinite forwards;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

@keyframes spinner {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}
