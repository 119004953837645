// Super admin light theme
:root .sa {
  --primary-color: #579ed8;
  --primary-contrast: #ffffff;

  --success-color: #7ac878;
  --success-contrast: #ffffff;

  --warn-color: yellow;
  --warn-contrast: #000000;

  --danger-color: #e65c4f;
  --danger-contrast: #ffffff;

  --light-color: #eaeff2;
  --medium-color: #305d7d;
  --dark-color: #2a3d55;

  --background-color1: #ffffff;
  --bakground-color2: #e4edff;
  --bakground-color3: #ddeafb;

  --background-table-color: rgba(221, 234, 251, 0.4);

  --box-shadow1: rgba(50, 103, 137, 0.15);

  --box-shadow2: rgba(223, 234, 253, 0.5);

  --header-transparent: rgba(255, 255, 255, 0.6);

  --header-btn-hover: rgba(255, 255, 255, 0.1);

  --city-admin-staff: rgba(82, 82, 82, 1);

  --tag-background: #579ed833;

}

// Super admin dark theme
:root .sa.dark {
  // TODO: REPLACE WITH DARK MODE COLORS
  --primary-color: #579ed8;
  --primary-contrast: #ffffff;

  --success-color: #7ac878;
  --success-contrast: #ffffff;

  --warn-color: yellow;
  --warn-contrast: #000000;

  --danger-color: #e65c4f;
  --danger-contrast: #ffffff;

  --light-color: #eaeff2;
  --medium-color: #305d7d;
  --dark-color: #2a3d55;

  --background-color1: #ffffff;
  --bakground-color2: #e4edff;
  --bakground-color3: #ddeafb;

  --background-table-color: rgba(221, 234, 251, 0.4);

  --box-shadow1: rgba(50, 103, 137, 0.15);

  --box-shadow2: rgba(223, 234, 253, 0.5);

  --header-transparent: rgba(255, 255, 255, 0.6);

  --city-admin-staff: rgba(82, 82, 82, 1);

  --tag-background: #579ed833;

}
