/* You can add global styles to this file, and also import other style files */
@import "styles/variables.scss";

/* For angular material cdk */
@import '@angular/cdk/overlay-prebuilt.css';

/* For spinner */
@import 'styles/spinner.scss';

/* For ngx otp input */
@import 'styles/ngx-otp-input.scss';

/* For swal2 alert styles */
@import 'styles/swal2-alert.scss';

// Import custom font styles
@import "styles/fonts.scss";

html,
body {
  margin: 0;
  height: 100%;
  font-size: 14px;
}

* {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  font-family: 'POPPINS', sans-serif;
}

/* Buttons */
.button,
button,
a {
  &.transparent {
    font-weight: bold;
    color: $primary-color;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration-color: transparent;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline 0.2em;
      transition: text-decoration-color 400ms;
      text-underline-offset: 0.5rem;
    }

    &.medium {
      color: $medium-color;
    }

    &.success {
      color: $success-color;
    }
  }

  &.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    font-size: 14px;

    border: 0;
    cursor: pointer;
    font-weight: 600;

    &.button-small {

      height: 37px;
      padding-right: 1rem;

      &.default {
        color: $primary-contrast;
        background-color: $primary-color;
      }

      &.outline {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: transparent;
      }
    }

    &.button-default {
      height: 43px;
      gap: 0.5rem;

      &.default {
        color: $primary-contrast;
        background-color: $primary-color;
      }

      &.outline {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: transparent
      }
    }

    &.button-medium {
      height: 37px;

      &.default {
        color: $primary-contrast;
        background-color: $medium-color;
      }

      &.outline {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: transparent
      }
    }

    &.button-large {
      height: 44px;

      &.default {
        color: $primary-contrast;
        background-color: $dark-color;
      }

      &.outline {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: transparent
      }
    }

    &.button-very-large {
      height: 43px;
      border-radius: 15px;
      color: $primary-color;
      background-color: $primary-contrast;
      box-shadow: 0 8px 25px 0 rgba(223, 234, 253, 0.2);
      gap: 0.5px;
    }
  }
}

input {
  color: $medium-color;
}

/* Select */
select {
  height: 38px;
  padding: 5px;
  border-radius: 25px;
  color: $medium-color;
  background-color: $background-color3;
  border: 5px solid $background-color3;
  cursor: pointer;

  font-size: 12px;

  &:focus-visible {
    outline: none;
  }

  &.outline {
    outline: 1px solid $primary-color;
    background-color: $primary-contrast;
    border: 4px solid $primary-contrast;

    &::after {
      content: 'test';
    }
  }
}

/* Checkbox */
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 10px;
  cursor: pointer;

  &.post {
    accent-color: $primary-color;
  }

  &.verified {
    accent-color: $primary-color;
  }
}

/* Input-box & Password */
input[type="text"], input[type="password"] {
  height: 34px;
  color: $medium-color;
  padding-left: 15px;
  font-family: 'POPPINS', sans-serif;
  border-radius: 25px;
  border: 1px solid $primary-color;

  &:focus {
    outline: none;
  }

}

::placeholder {
  color: $medium-color;
  opacity: 0.5;
}

/* Textarea */
textarea {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 10px;
  width: 223px;
  height: 177px;
  font-size: 12px;
  font-family: 'POPPINS', sans-serif;
  border-radius: 25px;
  border: 1px solid $primary-color;
  color: $medium-color;

  &:focus {
    outline: none;
  }
}

h1, p {
  font-family: 'POPPINS', sans-serif;
}

/* For Scroll Bar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* For buttons badge */
.badge {

  position: relative;
  display: inline-block;

  .badge-icon {
    position: absolute;
    width: 10px;
    text-align: center;
    top: -14px;
    right: 5px;
    padding: 5px 10px;
    border-radius: 50%;
    background: $danger-color;
    color: $primary-contrast;
    font-weight: bold;
    z-index: 10;
    font-size: 14px;
  }

}

/* For Review Text Line */
.review-text {
  font-family: 'POPPINS', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}

.tab-input:checked + .tab-box {
  font-size: 12px;
  font-weight: bold !important;
}

.highlight-search-text {
  color: $primary-color !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  background-color: unset !important;
}

.searchHighlight {
  color: $medium-color;
  font-size: 10px;

  font-weight: bold;
  display: none;
}

/* Tog customize the sweetalert2 alerts */
.swal2-actions button {
  display: inline-block;
  position: relative;
  padding: 14px 25px;
  color: $primary-contrast;
  line-height: 17px;
  font-weight: 600;
  border-radius: 4px;
}

.swal2-title {
  font-size: 25px !important;
  font-family: 'POPPINS', sans-serif !important;
}

.swal2-confirm {
  background: $primary-color !important;
}

.swal2-cancel {
  background: $primary-color !important;
}

.swal2-icon.swal2-warning {
  border-color: $primary-color !important;
  color: $primary-color !important;
}

.swal2-popup {
  width: 35em !important;
  padding: 50px !important;
}

/* For popup modals */
.modal-container {
  display: block;
  background: $primary-contrast;
  border-radius: 8px;
  padding: 8px 16px 16px;
}

::ng-deep .cdk-overlay-pane {
  align-items: center;
}

.restrict-words {
  color: $danger-color !important;
  text-underline: $danger-color;
  background: none;
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 2px;
  font-weight: bold;
  font-size: 14px;
  font-family: 'POPPINS', sans-serif;
}

.ngx-otp-input {
  width: 35px;
  height: 40px !important;
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid $primary-color !important;
  border-radius: 100% !important;
  outline: 0;
  padding-left: 0 !important;
}

.custom-a {
  text-decoration: unset;
  color: $primary-color;
}

.autocomplete-container {
  box-shadow: unset !important;

  .input-container {

    input::placeholder {
      color: $primary-color !important;
      opacity: 1 !important;
    }

    input {
      height: 38px !important;
      font-size: 12px !important;
      color: $medium-color !important;
      background: $primary-contrast !important;
      opacity: 0.7;
    }
  }

  a {
    font-size: 12px !important;
    color: $medium-color !important;
  }
}

.time-countdown {
  position: relative;

  .timer {
    position: absolute;
    width: 50%;
    right: 0;
  }

  .text {
    position: absolute;
    width: 50%;
    left: 0;
    margin-left: 15px;
  }
}

img {
  user-select: none;
}

notification-popup {
  position: absolute;
  top: 150px;
  right: 30px;
}

.disabledNav {
  pointer-events: none;
  opacity: 0.5;
}

@include media(">=mobile", ">=tablet") {
  .desktop-hidden {
    display: none;
  }

  .mobile-hidden {
    display: initial;
  }
}
