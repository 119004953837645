/* import all theme style files */
@import "./ca-theme.scss";
@import "./sa-theme.scss";
@import "./media.scss";

$primary-color: var(--primary-color);
$primary-contrast: var(--primary-contrast);

$success-color: var(--success-color);
$success-contrast: var(--success-contrast);

$warn-color: var(--warn-color);
$warn-contrast: var(--warn-contrast);

$danger-color: var(--danger-color);
$danger-contrast: var(--danger-contrast);

$light-color: var(--light-color);
$medium-color: var(--medium-color);
$dark-color: var(--dark-color);

$background-color1: var(--background-color1);
$background-color2: var(--bakground-color2);
$background-color3: var(--bakground-color3);

$box-shadow: var(--box-shadow1);
$box-shadow2: var(--box-shadow2);
$header-transparent: var(--header-transparent);
$header-btn-hover: var(--header-btn-hover);

$background-table-color: var(--background-table-color);

$city-staff: var(--city-admin-staff);

$tag-color: var(--tag-background);
