@import '../styles/variables';

//Tog customize the sweetalert2 alerts
.swal2-actions button {
  display: inline-block;
  position: relative;
  padding: 14px 25px;
  color: $primary-contrast;
  line-height: 17px;
  font-weight: 600;
  border-radius: 4px;
}

.swal2-title {
  font-size: 25px !important;
  font-family: 'POPPINS', sans-serif !important;
}

.swal2-confirm {
  background: $primary-color !important;
}

.swal2-cancel {
  background: $primary-color !important;
}

.swal2-icon.swal2-warning {
  border-color: $primary-color !important;
  color: $primary-color !important;
}

.swal2-popup {
  width: 35em !important;
  padding: 50px !important;
}
