@import '../styles/variables';

.ngx-otp-input {
  width: 35px;
  height: 40px !important;
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid $primary-color !important;
  border-radius: 100% !important;
  outline: 0;
  padding-left: 0 !important;
}

.otpInputError {
  .ngx-otp-input {
    border: 1px solid $danger-color !important;
  }
}
